import ls from "local-storage";

export const storeAuthUser = (key, val) => {
  const expires = new Date();
  expires.setMinutes(expires.getMinutes() + 30); // set for 30 min
  val.tokenExpiry = expires.getTime();

  ls.set(key, val);
  // console.log('user', ls.get(key));
  return val;
};

export const setValue = (key, val) => {
  ls.set(key, val);
  return val;
};

export const getValue = (key) => {
  const obj = ls.get(key);
  return obj ? obj : null;
};

export const removeValue = (key) => {
  ls.remove(key);
};

export const isExpired = (key) => {
  const obj = ls.get(key);
  const isSessionValid = obj ? obj.tokenExpiry - new Date().getTime() > 0 : false;
  if (isSessionValid) {
    return true;
  } else {
    ls.remove(key);
    return false;
  }
};

export const isTokenExpired = (key) => {
  const expectedExpirationTime = ls.get(key);
  const isSessionValid = expectedExpirationTime
    ? expectedExpirationTime - new Date().getTime() > 0
    : false;
  // console.log(expectedExpirationTime, new Date().getTime());
  if (isSessionValid) {
    ls.set("isPulseDbTokenExpired", true);
    return true;
  } else {
    ls.set("isPulseDbTokenExpired", false);
    // localStorage.clear();
    return false;
  }
};

// export const calculateLogoutTime = () => {
//   const expectedExpirationTime = ls.get("pulseDbTokenExpirationTime");
//   const crntTime = new Date().getTime();
//   const remainingTime = expectedExpirationTime - crntTime;
//   if (remainingTime <= 3600) {
//     ls.remove(key);  }
// };

/**
 * Fetched obj if its not expired else returns null
 */
export const getAuthUser = (key) => {
  const obj = ls.get(key);
  return obj ? obj : null;
};

export const removeAuthUser = (key) => {
  ls.remove(key);
  return true;
};

export const clearAll = () => {
  ls.clear();
  return true;
};
