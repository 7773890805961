import React, { Fragment } from 'react';
import MainHeader from '../components/Header/MainHeader';

const MainLayout = (props) => {
  return (
    <Fragment>
      <MainHeader />
      <div>
        {props.children}
      </div>
    </Fragment>
  );
};

export default MainLayout;
