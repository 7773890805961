import React, { Fragment } from "react";
// import Header from "../components/Header/Header";
import Header from "../components/Header/NewHeader";

const AppLayout = (props) => {
  return (
    <Fragment>
      <Header />
      <div className="app_layout">{props.children}</div>
    </Fragment>
  );
};

export default AppLayout;
