import React, { Component, Fragment } from "react";
import { Link, withRouter, NavLink } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
//import config from '../../config';
import logo from "../../assets/images/jw-symbol.svg";
import { PreLoginNAV } from "./navList";
import Nav from "react-bootstrap/Nav";
import "./Header.scss";
class MainHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Fragment>
        <div className="al_navbar">
          <Navbar
            collapseOnSelect
            variant="light"
            expand="lg"
            fixed="top"
            className="nav_background"
          >
            <div className="navbar-brand">
              <Link to="/">
              <img
                //src={config.s3_url + '/favicon.png'}
                src={logo}
                alt="logo"
                className="nav_logo"
              />
              {/* <div className="nav_logo_text">Jumbo wealth</div> */}
              </Link>
            </div>
            <Nav className="mr-auto"></Nav>
            <Fragment>
              {PreLoginNAV.map((item) => {
                return item.value ? (
                  <Fragment key={item.key}>
                    <Nav key={item.key} className="nav_item">
                      <NavLink to={item.path} activeClassName="active">
                        {item.value}
                      </NavLink>
                    </Nav>
                  </Fragment>
                ) : (
                  ""
                );
              })}
            </Fragment>
          </Navbar>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(MainHeader);
